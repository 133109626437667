import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import { Suspense } from 'react';

import { lazy } from 'react';

const VideoShow = lazy(() => import('./pages/VideoShow/VideoShow'));
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'));
const Layout = lazy(() => import('./Layout'));
const AboutUsNew = lazy(() => import('./pages/AboutUsNew/AboutUsNew'));
const Home = lazy(() => import('./pages/Home/Home'));
const Donate = lazy(() => import('./pages/Donate/Donate'));
const VideoList = lazy(() => import('./pages/VideoList/VideoList'));
const Contests = lazy(() => import('./pages/Contests/Contests'));
const ContestTerms = lazy(() => import('./pages/Contests/ContestTerms'));
const PlanetLove = lazy(() => import('./pages/Planets/PlanetLove'));
const PlanetBrain = lazy(() => import('./pages/Planets/PlanetBrain'));
const PlanetZen = lazy(() => import('./pages/Planets/PlanetZen'));
const PlanetGreen = lazy(() => import('./pages/Planets/PlanetGreen'));
const PlanetBling = lazy(() => import('./pages/Planets/PlanetBling'));
const PlanetFitopia = lazy(() => import('./pages/Planets/PlanetFitopia'));
const PlanetBliss = lazy(() => import('./pages/Planets/PlanetBliss'));
const MusicatShow = lazy(() => import('./pages/MusicatShow'));
const WellnessVideos = lazy(() => import('./pages/WellnessVideos/WellnessVideos'));
const OurMission = lazy(() => import('./pages/AboutUsNew/OurMission/OurMission'));
const OurStory = lazy(() => import('./pages/AboutUsNew/OurStory/OurStory'));
const Advisory = lazy(() => import('./pages/AboutUsNew/Advisors/Advisory'));
const OurMagicalCast = lazy(() => import('./pages/OurMagicalCast/OurMagicalCast'));
const LiveMusicHeals = lazy(() => import('./pages/LiveMusicHeals/LiveMusicHeals'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Home />
                    </Suspense>
                ),
            },
            {
                path: '/video/:slug',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <VideoShow />
                    </Suspense>
                ),
            },
            {
                path : '/contests',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Contests />
                    </Suspense>
                ),
            },
            {
                path : '/contact-us',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ContactUs />
                    </Suspense>
                ),
            },
            {
                path: '/musicat-show',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <MusicatShow />
                    </Suspense>
                ),
            },
            {
                path: '/live-music-heals-events',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <LiveMusicHeals />
                    </Suspense>
                ),
            },
            {
                path: '/our-magical-cast',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <OurMagicalCast />
                    </Suspense>
                ),
            },
            {
                path: '/wellness-videos',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <WellnessVideos />
                    </Suspense>
                ),
            },
            {
                path : '/our-mission',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <OurMission />
                    </Suspense>
                ),
            },
            {
                path : '/our-story',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <OurStory />
                    </Suspense>
                ),
            },
            {
                path : '/board-of-advisors',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Advisory />
                    </Suspense>
                ),
            },
            {
                path: '/planet-love',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetLove />
                    </Suspense>
                ),

            },
            {
                path: '/planet-brain',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetBrain />
                    </Suspense>
                ),
            },
            {
                path: '/planet-zen',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetZen />
                    </Suspense>
                ),
            },
            {
                path: '/planet-green',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetGreen />
                    </Suspense>
                ),
            },
            {
                path: '/planet-bling',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetBling />
                    </Suspense>
                ),
            },
            {
                path: '/planet-fitopia',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetFitopia />
                    </Suspense>
                ),
            },
            {
                path: '/planet-bliss',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <PlanetBliss />
                    </Suspense>
                ),
            },

            {
                path: '/about-us',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AboutUsNew />
                    </Suspense>
                ),
            },
            {
                path: '/contest-terms',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ContestTerms />
                    </Suspense>
                ),
            },
            {
                path: '/donate',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Donate />
                    </Suspense>
                ),
            },
            {
                path: '/videos',
                element: (
                    <Suspense fallback={<div>Loading...</div>}>
                        <VideoList show={true} handleClose={() => {}} />
                    </Suspense>
                ),
            },
            // 404 page
            {
                path: '*',
                element: <PageNotFound />,
            },
        ],
    },
]);

export function Routes() {
    return <RouterProvider router={router} />;
}